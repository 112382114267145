import React from 'react';
import { SubscriptionPlanCard } from 'renewal/Components/PlanCard';
import { Link } from 'react-router-dom';
import ExpiredCardUpdate from 'payment/Components/ExpiredCardUpdate';
import PostUpdatePaymentEmailModal from 'payment/Components/PostUpdatePaymentEmailModal';
import AvailablePlansSection from 'renewal/Components/AvailablePlansSection';
import { AvailablePlan } from 'renewal/domain/model/AvailablePlan';
import { SubscriptionStatus } from 'core/domain/model';
import Membership from 'core/domain/model/Membership';
import AddMoreDays from 'renewal/Components/AddMoreDaysButton';
import { isDailyMembership } from 'core/domain/service/MembershipService';
import utils from 'core/utils';
import NewHardwareOffer from 'renewal/Pages/NewHardwareOffer/NewHardwareOffer';
import NoCardOnFile from 'payment/Components/NoCardOnFile';
import { useFlags } from 'featureFlags/FlagContext';

interface SubscriptionPlanTemplateProps {
  membership: Membership
  // available plans for the user to renew or change to
  availablePlans: AvailablePlan[]
  // price for an extra day of skiing
  dailyPrice: string
  isUpsellPlans: boolean
  // whether there is an available plan that is the same skuKind as the current
  newerPlan: AvailablePlan | undefined
  // feature flags
  isPaymentEnabled: boolean
  isAvailablePlansEnabled: boolean
  isAddMoreDaysEnabled: boolean
  // function calls / state
  onClick: () => void
  modalIsOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  isSubmittingEmailUpdate: boolean
}

const SubscriptionPlanTemplate = (props: SubscriptionPlanTemplateProps) => {
  const {
    membership,
    availablePlans,
    dailyPrice,
    isUpsellPlans,
    newerPlan,
    isPaymentEnabled,
    isAvailablePlansEnabled,
    isAddMoreDaysEnabled,
    isSubmittingEmailUpdate,
    onClick, modalIsOpen, setIsOpen,
  } = props;

  const flags = useFlags();
  const { subscription } = membership;

  if (subscription === null) throw new Error('incorrect usage of SubscriptionPlanTemplate. no subscription on the membership');

  const isActive = subscription.status === SubscriptionStatus.ACTIVE;

  const isTrial = membership.productTitle === '1 month trial';

  const isDaily = isDailyMembership(membership);

  const isMembershipEndDatesEnabled = flags.getIsActive('membership-end-dates');
  return (
    <section className="w-full">
      <div className="container container--default">
        <PostUpdatePaymentEmailModal
          isOpen={modalIsOpen}
          setIsOpen={setIsOpen}
          className="bg-grey-200 rounded-12 py-48 m-48 shadow-grey-50%-md border border-grey-300"
        />
      </div>

      {isActive ? (
        <>
          <h5 className="text-24 font-bold text-blue-900">Manage Plan</h5>

          <SubscriptionPlanCard
            membership={membership}
            newerPlan={newerPlan}
            isCurrent
          />
          {isUpsellPlans && isDaily && isAddMoreDaysEnabled ? (
            <AddMoreDays membership={membership} dailyPrice={dailyPrice} />
          ) : null}
        </>
      ) : <h5 className="text-18 font-bold text-blue-900 mb-12">Payment Method On File</h5>}
      {isPaymentEnabled && membership.isOwner && subscription.paymentMethod ? (
        <ExpiredCardUpdate
          onClick={onClick}
          paymentMethod={subscription.paymentMethod}
          isSubmtting={isSubmittingEmailUpdate}
          subscription={subscription}
        />
      ) : null}
      {isPaymentEnabled && membership.isOwner && !subscription.paymentMethod ? (
        <NoCardOnFile />
      ) : null}
      {isTrial && (
        <div className="mt-24 mb-12 text-center">
          <p className="text-20 font-bold text-blue-900 mb-12">Can we help?</p>
          <p className="text=16 mb-12">If you aren’t 100% satisfied with Carv you can return it in 100 days for a full refund</p>
          <a href="https://getcarv.com/returns/">
            <button
              type="button"
              className="btn btn--sec btn--lg w-full"
            >
              Go To returns
            </button>
          </a>
          <a href="https://getcarv.com/contact">
            <button
              type="button"
              className="btn btn--sec btn--lg w-full my-12"
            >
              Contact Support
            </button>
          </a>
        </div>

      )}
      {isAvailablePlansEnabled && membership.isOwner && !isTrial ? (
        <div className="mt-48">
          {isActive
            ? (
              <>
                <h5 className="text-18 font-bold text-blue-900">Your Next Plan</h5>
                {isMembershipEndDatesEnabled && <p className="text-18  text-blue-900">Starting {utils.toLongDate(subscription.nextPayment)}</p>}
              </>
            )
            : (
              <>
                <h5 className="text-18 font-bold text-blue-900">Renew Your Plan</h5>
                <p className="text-18  text-blue-900">Starting Today</p>
              </>
            )}
          <AvailablePlansSection
            availablePlans={availablePlans}
            currentSubscriptionId={subscription.id}
            isCurrentPlanActive={isActive}
          />
        </div>
      ) : null}
      {membership.upgrade_offer && (
        <NewHardwareOffer offerUuid={membership.upgrade_offer} />
      )}
      {membership.isOwner && isActive ? (
        <Link
          to={`/renewal/${subscription.id}/what-you-will-lose`}
          className="btn btn--ter btn--lg w-full"
          data-testid="cancel-button"
          data-id="cancel-start-button"
          data-subscription-id={subscription.id}
        >
          CANCEL SUBSCRIPTION
        </Link>
      ) : null}
    </section>
  );
};

export default SubscriptionPlanTemplate;
