import { useQuery } from '@tanstack/react-query';
import apiClient from 'core/api/api-client';
import { BuddyPassesResponse } from './dto/BuddyPassesRequestDTO';
import BuddyPassRepo from './BuddyPassRepo';

const BUDDY_PASS_REQUEST_KEY = 'buddyPasses';
const useBuddyPasses = () => useQuery(
  [BUDDY_PASS_REQUEST_KEY],
  async () => {
    const response = await apiClient.get<BuddyPassesResponse>('/buddy_passes');
    return response.data;
  },
);

const repo: BuddyPassRepo = { useBuddyPasses };

export default repo;
