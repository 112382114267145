/* eslint-disable max-len */
/* Matches mock subscriptions to correct available plan responses.
* setting the current_plan property to true for the matching variant.
* The matching is done based on the first two digits of the subscription ID.
* both Mock subscription IDs and mock variant ids start with:
*   12 month ---- 10
*   24 month ---- 30
*   6 days ---- 26
*   7 days ---- 27
*   8 days ---- 28
*   9 days ---- 29
*/

interface Variant {
  id: string;
  price: string;
  currency: string;
  description: string;
  duration: string;
  day_limit: number;
  current_plan: boolean
}

const match = (subscripitonId: string | readonly string[], variants: Variant[]) => {
  const newVariants = variants;
  const firstDigit = subscripitonId[0];
  const secondDigit = subscripitonId[1];
  // eslint-disable-next-line no-param-reassign
  const matchingIndex = variants.findIndex((v) => v.id[0] === firstDigit && v.id[1] === secondDigit);
  if (matchingIndex !== -1) {
    newVariants[matchingIndex].current_plan = true;
    return newVariants;
  }
  return variants;
};

const matcher = { match };

export type { Variant };

export default matcher;
