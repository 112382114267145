import { useAuth } from 'core/auth/UserContext';
import { UNKNOWN_NAME } from 'core/domain/const';
import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Badge } from 'svg/skiIQBadge.svg';
import { ReactComponent as UnkownBadge } from 'svg/skiIQUnkownBadge.svg';
import EmptyProfilePic from './EmptyProfilePic';

const getDisplayName = (firstName: string) => {
  let displayName = `${firstName}'`;

  if (firstName === UNKNOWN_NAME) {
    return 'Your';
  } if (firstName.slice(-1) !== 's') {
    displayName = displayName.concat('s');
  }
  return displayName;
};

const ProfileCard = () => {
  const auth = useAuth();

  if (!auth?.user) return null;
  const { user } = auth;

  const skiIq = Math.round(user.maxSkiIQ);

  const displayName = getDisplayName(user.firstName);
  return (
    <Link to="/">
      <div className="relative inline-block mb-12">
        {user.photoUrl ? <img className="rounded-full z-0 h-100 w-100" src={user.photoUrl} alt="User profile" /> : <EmptyProfilePic firstName={user.firstName} lastName={user.lastName} />}
        <div className="absolute -bottom-8 -left-8 z-1">
          <div className="relative">
            {skiIq === 0 ? <UnkownBadge className="w-40 h-50 bottom-0 left-0 overflow-hidden align-middle" /> : <Badge className="w-40 h-50 bottom-0 left-0 overflow-hidden align-middle" />}
            <p className="text-white text-16 text-center font-heavy absolute top-50% left-50% -translate-x-50% -translate-y-50% z-2">
              {skiIq === 0 ? '?' : skiIq}
            </p>
          </div>

        </div>
      </div>
      <div className="mb-48">
        <h3 className="uppercase text-blue-700 text-center text-36 font-heavy leading-none">
          {displayName} Membership
        </h3>
        <p className="text-blue-500 text-center text-16" data-testid="user-email">
          {user.email}
        </p>
      </div>

    </Link>
  );
};

export default ProfileCard;
