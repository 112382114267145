import { BASE_URL } from 'core/api/api-client';
import { rest } from 'msw';
import user from './json/user.json';
import memberships from './json/memberships.json';
import session from './json/session.json';
import variants from './json/variants.json';
import upsellVariants from './json/upsellVariants.json';

import checkout from './json/checkout.json';
import endOfSeasonOffer from './json/endOfSeasonOffer.json';
import warranty from './json/warranty.json';
import warranties from './json/warranties.json';
import hardwareUpgradeOffer from './json/hardwareUpgradeOffer.json';
import buddyPasses from './json/buddyPasses.json';
import matcher, { Variant } from '../subscriptionToPlanMatcher';

const handlers = [
  // LOGIN and AUTH
  rest.post(`${BASE_URL}/magic/email/:email`, (req, res, ctx) => res(
    ctx.status(200),
  )),

  rest.post(`${BASE_URL}/magic/session/:uuid`, (req, res, ctx) => res(
    ctx.status(200),
    ctx.json(session),
  )),

  rest.get(`${BASE_URL}/user`, (req, res, ctx) => res(
    ctx.status(200),
    ctx.json(user),
  )),

  rest.post(`${BASE_URL}/magic/email_owner/:id`, (req, res, ctx) => res(
    ctx.delay(1000),
    ctx.status(200),
  )),

  // Password Reset
  rest.post(`${BASE_URL}/password_reset`, (req, res, ctx) => res(
    ctx.status(200),
  )),

  rest.post(`${BASE_URL}/password_reset/:uuid`, (req, res, ctx) => res(
    ctx.delay(2000),
    ctx.status(200),
  )),

  // Subscriptions
  rest.get(`${BASE_URL}/memberships`, (req, res, ctx) => res(
    ctx.status(200),
    ctx.json(memberships),
  )),

  rest.post(`${BASE_URL}/subscriptions/:id/cancel`, (req, res, ctx) => res(
    ctx.delay(2000),
    ctx.status(200),
  )),

  rest.put(`${BASE_URL}/subscriptions/:id/variant/:variant_id`, (req, res, ctx) => res(
    ctx.delay(2000),
    ctx.status(200),
  )),

  rest.put(`${BASE_URL}/subscriptions/:id/renewal_date`, (req, res, ctx) => res(
    ctx.delay(2000),
    ctx.status(200),
  )),

  rest.get(`${BASE_URL}/subscriptions/:id/variants`, (req, res, ctx) => {
    const jsonValue = matcher.match(req.params.id, variants.variants as Variant[]);
    return res(
      ctx.status(200),
      ctx.json({ ...variants, variants: jsonValue }),
    );
  }),

  rest.get(`${BASE_URL}/memberships/:id/variants`, (req, res, ctx) => {
    const jsonValue = matcher.match(req.params.id, variants.variants as Variant[]);
    return res(
      ctx.status(200),
      ctx.json({ ...variants, variants: jsonValue }),
    );
  }),

  rest.get(`${BASE_URL}/memberships/:id/upsell_variants`, (req, res, ctx) => res(
    ctx.status(200),
    ctx.json(upsellVariants),
  )),

  /*
    Payment
  */
  rest.post(`${BASE_URL}/payment_method/:id/send_update_email`, (req, res, ctx) => res(
    ctx.delay(2000),
    ctx.status(200),
  )),

  rest.post(`${BASE_URL}/subscriptions/:id/reactivate`, (req, res, ctx) => res(
    ctx.delay(2000),
    ctx.status(200),
    ctx.json(checkout),
  )),

  rest.post(`${BASE_URL}/subscriptions/:id/cart`, (req, res, ctx) => res(
    ctx.delay(2000),
    ctx.status(200),
    ctx.json(checkout),
  )),

  rest.post(`${BASE_URL}/memberships/:id/cart`, (req, res, ctx) => res(
    ctx.delay(2000),
    ctx.status(200),
    ctx.json(checkout),
  )),

  rest.post(`${BASE_URL}/memberships/:id/upsell_variants/cart`, (req, res, ctx) => res(
    ctx.delay(2000),
    ctx.status(200),
    ctx.json(checkout),
  )),

  // End of season offer
  rest.get(`${BASE_URL}/end_of_season_offer`, (req, res, ctx) => res(
    ctx.status(200),
    ctx.json(endOfSeasonOffer),
  )),
  rest.post(`${BASE_URL}/end_of_season_offer/:id/redeem`, (req, res, ctx) => res(
    ctx.delay(2000),
    ctx.status(200),
    ctx.json(checkout),
  )),
  rest.post(`${BASE_URL}/carv_2_upgrade_offer/:id/redeem/hardware`, (req, res, ctx) => res(
    ctx.delay(2000),
    ctx.status(200),
    ctx.json(checkout),
  )),
  rest.post(`${BASE_URL}/carv_2_upgrade_offer/:id/redeem/renewal`, (req, res, ctx) => res(
    ctx.delay(2000),
    ctx.status(200),
    ctx.json(checkout),
  )),

  rest.get(`${BASE_URL}/carv_2_upgrade_offer/:id`, (req, res, ctx) => res(
    ctx.status(200),
    ctx.json(hardwareUpgradeOffer),
  )),

  // Warranty
  rest.get(`${BASE_URL}/warranty/:id`, (req, res, ctx) => res(
    ctx.status(200),
    ctx.json(warranty),
  )),

  rest.post(`${BASE_URL}/warranty/:id`, (req, res, ctx) => res(
    ctx.delay(2000),
    ctx.status(200),
    ctx.json(checkout),
  )),

  rest.get(`${BASE_URL}/warranties`, (req, res, ctx) => res(
    ctx.status(200),
    ctx.json(warranties),
  )),

  rest.get(`${BASE_URL}/buddy_passes`, (req, res, ctx) => res(
    ctx.status(200),
    ctx.json(buddyPasses),
  )),
  /*
  this will cause an error in chatbotize script
  but means it doesn't call the chatbotize servers all the time
  */
  rest.get('*chatbotize*', (req, res, ctx) => res(
    ctx.status(200),
  )),
];

export default handlers;
