import React, { useState } from 'react';

import { IoCheckmark, IoCopyOutline } from 'react-icons/io5';

interface CopyButtonProps {
  text: string;
}

const CopyButton = ({ text }: CopyButtonProps) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <button
      type="button"
      onClick={handleCopy}
      className="p-4 text-gray-400"
      aria-label="Copy code"
    >
      {copied ? <IoCheckmark className="w-20 h-20 text-green-500" /> : <IoCopyOutline className="w-20 h-20" />}
    </button>
  );
};

export default CopyButton;
