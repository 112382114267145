import React, { useState, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { ReactComponent as InfoIcon } from 'svg/info.svg';

const Portal = ({ children } : { children: any }) => createPortal(
  children,
  document.body, // Render the children into the body element
);

type Type = 'alert' | 'info' | 'success';

type Position = 'top' | 'right';
interface TooltipProps {
  message: string;
  type: Type;
  position?: Position;
}

const Tooltip = ({ message, type, position } : TooltipProps) => {
  const [showMessage, setShowMessage] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const iconRef = useRef<SVGSVGElement>(null);

  const bgColor = {
    alert: 'bg-red-200',
    info: 'bg-blue-500',
    success: 'bg-green-500',
  };

  const fillColour = {
    alert: 'stroke-red-500 fill-red-500',
    info: 'text-blue-500 fill-blue-500',
    success: 'text-green-500 fill-green-500',
  };

  useEffect(() => {
    if (showMessage && iconRef.current) {
      const iconRect = iconRef.current.getBoundingClientRect();
      setTooltipPosition({
        top: position === 'top' ? iconRect.top - 35 : iconRect.top + window.scrollY,
        left: position === 'top' ? iconRect.left - 100 : iconRect.left + window.scrollX + iconRect.width + 8,
      });
    }
  }, [showMessage]);

  return (
    <div className="flex ml-12">
      <InfoIcon
        ref={iconRef}
        className={`${fillColour[type]}`}
        onMouseEnter={() => setShowMessage(true)}
        onMouseLeave={() => setShowMessage(false)}
        onClick={() => setShowMessage(!showMessage)}
      />
      <Portal>
        <div
          className={`fixed z-50 p-2 px-8 rounded-6 transition-opacity ease-in-out duration-300 ${bgColor[type]} ${showMessage ? 'opacity-100' : 'opacity-0'}`}
          style={{ top: tooltipPosition.top, left: tooltipPosition.left }}
        >
          {message}
        </div>
      </Portal>
    </div>
  );
};

Tooltip.defaultProps = { position: 'right' };

export default Tooltip;
