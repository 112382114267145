import flags from 'flags.json';
import { Flag, FlagName } from './types';

const currentEnv = process.env.REACT_APP_ENVIRONMENT;
const PRODUCTION = 'production';
const PREVIEW = 'preview';
const DEVELOPMENT = 'development';
const TEST = 'test';
const OFF = 'off';

const buildTypes = [PRODUCTION, PREVIEW, DEVELOPMENT, TEST, OFF];

const enabledUpTo = (upTo: string) => {
  if (!buildTypes.includes(upTo)) throw new Error(`build type "${upTo}" not recognised`);
  switch (currentEnv) {
    case (PRODUCTION):
      if (upTo === PRODUCTION) return true;
      return false;
    case (PREVIEW):
      if (upTo === PRODUCTION || upTo === PREVIEW) return true;
      return false;
    case (TEST):
    case (DEVELOPMENT):
      if (upTo === PRODUCTION || upTo === PREVIEW || upTo === DEVELOPMENT) return true;
      return false;
    default:
      return false;
  }
};

const evaluatedFlags: Flag[] = [];

flags.forEach((jsonFlag) => {
  evaluatedFlags.push({
    name: jsonFlag.name as FlagName, // unsafe. ensure the name is a FlagName
    isActive: enabledUpTo(jsonFlag.enabledTo),
  });
});

export default evaluatedFlags;
