import { QueryOptions } from 'core/api/CoreHttpRepo';
import CoreService from 'core/domain/interface/CoreService';
import { SubscriptionStatus } from '../model';
import Membership from '../model/Membership';
import { UNLIMITED_SKI_DAYS } from '../const';

const isDailyMembership = (membership: Membership)
: boolean => membership.skiDays !== UNLIMITED_SKI_DAYS;

const isCancelledOrLapsed = (
  status: SubscriptionStatus,
) => status === SubscriptionStatus.CANCELLED || status === SubscriptionStatus.LAPSED;

const useMembershipService = (options?: QueryOptions) => {
  const { data: memberships, ...rest } = CoreService.useMemberships(options);

  const getMemberships = () => memberships?.sort((a, b) => {
    if (!a.subscription) return 1;
    if (!b.subscription) return -1;
    if (isCancelledOrLapsed(a.subscription.status) && isCancelledOrLapsed(b.subscription.status)) {
      return 0;
    }
    if (isCancelledOrLapsed(a.subscription.status) && !isCancelledOrLapsed(b.subscription.status)) {
      return 1;
    }
    return -1;
  });

  const getMembershipById = (id: string) => {
    if (memberships === undefined) return undefined;
    const membership = memberships.filter((p) => p.id === id);
    try {
      if (membership === undefined || membership.length !== 1) {
        throw new Error('Could not retrieve membership by id');
      }
      return membership[0];
    } catch {
      return null;
    }
  };

  const getMembershipBySubscriptionId = (id: string) => {
    if (memberships === undefined) return undefined;
    const idAsInt = parseInt(id, 10);
    const membership = memberships
      .filter((p) => p.subscription)
      .filter((p) => p.subscription?.id === idAsInt);
    try {
      if (membership === undefined || membership.length !== 1) {
        throw new Error('Could not retrieve membership by subscription id');
      }
      return membership[0];
    } catch {
      return null;
    }
  };

  const getQueryResultValues = () => rest;

  return { getMemberships, getMembershipById, getQueryResultValues, getMembershipBySubscriptionId };
};

export { isDailyMembership };

export default useMembershipService;
