import { User } from 'core/auth/types';
import { UNKNOWN_NAME, UNLIMITED_SKI_DAYS } from 'core/domain/const';
import currency from 'core/hooks/currency';
import paymentMapper from 'payment/api/mapper';
import { Subscription, SubscriptionStatus } from 'core/domain/model';
import Membership from 'core/domain/model/Membership';
import MembershipType from 'core/domain/model/MembershipType';
import PaymentError from 'core/domain/model/PaymentError';
import { toDomainAvailablePlan } from 'renewal/api/mapper';
import { UserDTO } from './dto';
import { SubscriptionUserDTO } from './dto/User';
import utils from '../utils';
import { ContractDTO } from './dto/ContractDTO';

const toDomainUser = (userDTO : UserDTO | null) : User | null => {
  if (userDTO === null) return null;
  const user: User = {
    firstName: userDTO.user?.first_name || UNKNOWN_NAME,
    lastName: userDTO.user?.last_name || '',
    maxSkiIQ: userDTO.user?.max_ski_iq || 0,
    photoUrl: userDTO.user?.photo_url || '',
    email: userDTO.user.email || '', // set elswhere? needs thinking about. blank for now
    token: '',
  };
  return user;
};

const toDomainSubUser = (userSubDTO : SubscriptionUserDTO | null) : User | null => {
  if (userSubDTO === null) return null;
  const user: User = {
    firstName: userSubDTO.first_name || UNKNOWN_NAME,
    lastName: userSubDTO.last_name || '',
    maxSkiIQ: userSubDTO.max_ski_iq || 0,
    photoUrl: userSubDTO.s3_photo || '',
    email: userSubDTO.email,
    token: '',
  };
  return user;
};

// const toDomainErrorCode = (errorCode: string | null) : string | null => {
//   if (errorCode === null) return null;
//   return errorCode;
// };

const toDomainSubscription = (contractDTO : ContractDTO, end_date: string)
: Subscription | null => {
  if (contractDTO === null || contractDTO.subscription === null) return null;
  const { subscription: subscriptionDTO } = contractDTO;
  const subscription: Subscription = {
    id: subscriptionDTO.id,
    variant: toDomainAvailablePlan(subscriptionDTO.variant),
    status: subscriptionDTO.status.toUpperCase() as SubscriptionStatus,
    lastPaymentStatus: subscriptionDTO.last_payment_status,
    errorCode: subscriptionDTO.error_code as PaymentError,
    price: parseInt(currency.useFormatMoneyPartsValue(
      subscriptionDTO.price,
      subscriptionDTO.presentment_currency,
    ), 10),
    currency: subscriptionDTO.presentment_currency,
    currencySymbol: currency.useFormatMoneyPartsSymbol(
      subscriptionDTO.price,
      subscriptionDTO.presentment_currency,
    ),
    nextPayment: new Date(subscriptionDTO.next_billing_date),
    endDate: utils.toLongDate(end_date),
    orderInterval: parseInt(subscriptionDTO.billing_interval_count, 10),
    orderIntervalUnit: subscriptionDTO.billing_interval,
    skuKind: subscriptionDTO.sku_kind,
    paymentMethod: paymentMapper.toDomainPaymentMethod(
      contractDTO.payment,
      contractDTO.subscription.next_billing_date,
    ),
  };
  return subscription;
};

const fromMembership = (contractDTO: ContractDTO) : Membership => ({
  id: contractDTO.membership.uuid,
  type: contractDTO
    .membership?.membership_type.toUpperCase() as MembershipType,
  // TODO check if membership may be null
  productTitle: contractDTO.membership.description
  || contractDTO.membership?.membership_type.toUpperCase() as MembershipType,
  user: toDomainSubUser(contractDTO.user),
  code: contractDTO.membership.code || '',
  skiDays: contractDTO.membership.day_limit
    ? contractDTO.membership.day_limit.toString() : UNLIMITED_SKI_DAYS,
  isOwner: contractDTO.subscription ? contractDTO.subscription.owner : true,
  ownerEmail: contractDTO.subscription
    ? contractDTO.subscription.owner_email : contractDTO.user.email,
  endDate: utils.toLongDate(contractDTO.membership.end_date),
  subscription: toDomainSubscription(contractDTO, contractDTO.membership.end_date || ''),
  upgrade_offer: contractDTO.subscription
    ? contractDTO.subscription.upgrade_offer : contractDTO.membership.upgrade_offer,
});

const fromSubscription = (contractDTO: ContractDTO) : Membership => ({
  id: contractDTO.subscription!.id.toString(),
  type: contractDTO
    .subscription?.sku_kind.toUpperCase() as MembershipType,
  // TODO check if membership may be null
  productTitle: contractDTO.subscription!.description,
  user: toDomainSubUser(contractDTO.user),
  code: contractDTO.membership?.code || '',
  skiDays: contractDTO.membership?.day_limit
    ? contractDTO.membership.day_limit.toString() : UNLIMITED_SKI_DAYS,
  isOwner: contractDTO.subscription!.owner,
  ownerEmail: contractDTO.subscription!.owner_email,
  endDate: utils.toLongDate(
    contractDTO.membership?.end_date,
  ),
  subscription: toDomainSubscription(
    contractDTO,
    contractDTO.subscription!.next_billing_date,
  ),
  upgrade_offer: contractDTO.subscription!.upgrade_offer,
});

const toDomainMembership = (contractDTO : ContractDTO) : Membership => {
  if (contractDTO.membership) return fromMembership(contractDTO);
  return fromSubscription(contractDTO);
};

const mapper = { toDomainSubscription, toDomainUser, toDomainSubUser, toDomainMembership };

export default mapper;
