import BuddyPassRepo from 'buddyPass/api/BuddyPassRepo';
import httpRepo from 'buddyPass/api/BuddyPassHttpRepo';
import mapper from 'buddyPass/api/mapper';

const useBuddyPasses = (repo: BuddyPassRepo = httpRepo) => {
  const { data, ...rest } = repo.useBuddyPasses();
  if (!data) return { data: undefined, ...rest };
  const mappedData = data.buddy_passes
    .map(mapper.toDomainBuddyPass);
  return { data: mappedData, ...rest };
};

export interface WarrantyService {
  useBuddyPasses: (repo?: BuddyPassRepo) => ReturnType<typeof useBuddyPasses>;

}

const service = { useBuddyPasses };

export default service;
