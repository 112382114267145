import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFlags } from 'featureFlags/FlagContext';
import useAvailablePlanService from 'renewal/domain/service/AvailablePlanService';
import { RouteParams } from 'core/routing/Routing';
import useGa from 'core/hooks/ga';
import paymentService from 'payment/domain/interface/PaymentService';
import LoadingIndicator from 'core/Components/LoadingIndicator';
import useMembershipService from 'core/domain/service/MembershipService';
import SubscriptionPlanTemplate from './SubscriptionPlanTemplate';

const SubscriptionPlan = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const { uuid } = useParams<keyof RouteParams>() as RouteParams;
  const ga = useGa();
  const flags = useFlags();

  const membership = useMembershipService().getMembershipBySubscriptionId(uuid);
  const planService = useAvailablePlanService(uuid, membership?.id);
  const availablePlans = planService.getPlans();

  const { submit, isSubmitting } = paymentService.useSendPaymentUpdateEmail();

  // loading indicator during query load
  if (!membership || !availablePlans) {
    return (
      <LoadingIndicator />
    );
  }

  const onClick = () => {
    if (membership.subscription === null) return;
    if (membership.subscription.paymentMethod === null) return;
    ga.clickEvent('update_email', '');
    submit({ paymentMethodId: membership.subscription.paymentMethod.id }, () => {
      setIsOpen(true);
    });
  };

  const isPaymentEnabled = flags.getIsActive('payment');
  const isAvailablePlansEnabled = flags.getIsActive('available-plans');
  const isAddMoreDaysEnabled = flags.getIsActive('add-more-days');

  const dailyPrice = planService.getDailyPrice();

  const upsellPlans = planService.getUpsellPlans();
  const currencySymbol = upsellPlans?.[0]?.currencySymbol || '';

  const isUpsellPlans = upsellPlans && upsellPlans.length > 0;

  const newerPlan = membership.subscription
    ? planService.getNewerPlan(membership.subscription) : undefined;

  return (
    <SubscriptionPlanTemplate
      isPaymentEnabled={isPaymentEnabled}
      isAvailablePlansEnabled={isAvailablePlansEnabled}
      isAddMoreDaysEnabled={isAddMoreDaysEnabled}
      isUpsellPlans={!!isUpsellPlans}
      newerPlan={newerPlan}
      dailyPrice={currencySymbol + dailyPrice}
      membership={membership}
      onClick={onClick}
      modalIsOpen={modalIsOpen}
      setIsOpen={setIsOpen}
      availablePlans={availablePlans}
      isSubmittingEmailUpdate={isSubmitting}
    />
  );
};

export default SubscriptionPlan;
