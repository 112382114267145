import React, { useState } from 'react';
import { FaChevronLeft, FaChevronDown } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import LoadingIndicator from 'core/Components/LoadingIndicator';
import service from 'buddyPass/domain/interface/BuddyPassService';
import RootPage from 'core/Pages/RootPage';
import BuddyPass from 'buddyPass/domain/model/BuddyPass';
import CopyButton from 'buddyPass/Components/CopyButton';

const UnredeemedPassCard = (pass: BuddyPass) => {
  const { code } = pass;
  return (
    <div
      key={code}
      className="border rounded-6 py-12 px-24 border-blue-400 bg-blue-250 shadow-grey-50%-md"
    >
      <div>
        <div className="flex items-center py-8  border-grey-350">
          <span className="text-18 grow block">Code</span>
          <div className="flex gap-4">
            <span className="text-18 flex shrink-0 text-blue-900">
              {code}
            </span>
            <CopyButton text={code} />
          </div>
        </div>
      </div>
    </div>
  );
};

const RedeemedPassCard = (pass: BuddyPass) => {
  const { code, redeemerEmail } = pass;
  return (
    <div
      key={code}
      className="border rounded-6 py-28 px-24 border-blue-400 bg-white"
    >
      <div className="mb-12">
        <div className="flex items-center py-16 border-b border-grey-350">
          <span className="text-18 grow block">Code</span>
          <span className="text-18 flex shrink-0 text-blue-900 line-through">
            {code}
          </span>
        </div>

        <div className="flex items-center pt-16">
          <span className="text-18 grow block">Buddy</span>
          <span className="text-18 flex shrink-0 text-blue-900">
            {redeemerEmail}
          </span>
        </div>
      </div>
    </div>
  );
};

const BuddyPasses = () => {
  const navigate = useNavigate();
  const { data, isLoading } = service.useBuddyPasses();
  const [unredeemedLimit, setUnredeemedLimit] = useState(5);
  const [redeemedLimit, setRedeemedLimit] = useState(5);

  if (isLoading) return <LoadingIndicator />;
  if (!data) return <div>No buddy passes found</div>;

  const unredeemed = data.filter((pass) => !pass.redeemerEmail);
  const redeemed = data.filter((pass) => pass.redeemerEmail);

  return (
    <RootPage>
      <div className="flex flex-col gap-24 w-full bg-grey-200 md:p-40 pb-40">
        <button
          type="button"
          className="flex items-center text-blue-900"
          onClick={() => navigate('/')}
        >
          <FaChevronLeft className="mr-8" />
          Back
        </button>
        <div className="flex flex-col">
          <h5 className="text-24 font-bold text-blue-900 mb-4">Your Buddy Passes</h5>
          <p className="mb-12">Share your Carv hardware and a code with a
            friend to let them enjoy a 1-day free trial of Carv
          </p>

        </div>
        <div className="flex flex-col gap-12">
          <p className="text-blue-400"> {`${unredeemed.length} passes available`}</p>
          <div className="flex flex-col gap-32 w-full">
            {unredeemed.slice(0, unredeemedLimit).map(UnredeemedPassCard)}
            {unredeemed.length > unredeemedLimit && (
              <button
                type="button"
                className="text-blue-900 flex items-center justify-center"
                onClick={() => setUnredeemedLimit(unredeemedLimit + 5)}
              >
                Show More  <FaChevronDown className="ml-12" />
              </button>
            )}
          </div>
        </div>
        <h5 className="text-24 font-bold text-blue-900 border-t border-grey-400 pt-32">Redeemed Buddy Passes</h5>
        {redeemed.slice(0, redeemedLimit).map(RedeemedPassCard)}
        {redeemed.length > redeemedLimit && (
          <button
            type="button"
            className="text-blue-900 flex items-center justify-center"
            onClick={() => setRedeemedLimit(redeemedLimit + 5)}
          >
            Show More  <FaChevronDown className="ml-12" />
          </button>
        )}
      </div>
    </RootPage>
  );
};

export default BuddyPasses;
