import BuddyPass
  from 'buddyPass/domain/model/BuddyPass';
import { BuddyPassDTO } from './dto/BuddyPassesRequestDTO';

const toDomainBuddyPass = (dto: BuddyPassDTO): BuddyPass => ({
  code: dto.code,
  redeemerEmail: dto.redeemer_email,
});

export default { toDomainBuddyPass };
