import React, { useEffect, useState } from 'react';
import RenewalService from 'renewal/domain/interface/RenewalService';
import PaymentService from 'payment/domain/interface/PaymentService';
import LoadingIndicator from 'core/Components/LoadingIndicator';
import { useErrorModal } from 'core/context/ErrorModalContext';
import { useParams } from 'react-router-dom';
import { RouteParams } from 'core/routing/Routing';
import HardwareUpgradeOffer from 'renewal/domain/model/HardwareUpgradeOffer';
import useRedirectModal from 'payment/Components/RedirectModal';
import GenericModal from 'core/Components/GenericModal';
import OfferType from 'renewal/domain/model/OfferType';
import NewHardwareOfferTemplate from './NewHardwareOffersTemplate';

interface Props {
  // eslint-disable-next-line react/require-default-props
  offerUuid?: string
}

const NewHardwareOffer = ({ offerUuid }: Props) => {
  /*
   STATE & HOOKS
  */
  const { uuid } = useParams<keyof RouteParams>() as RouteParams;
  const { initRedirect } = useRedirectModal(0);
  const errorModal = useErrorModal();

  const [currentOffer, setCurrentOffer] = useState<HardwareUpgradeOffer | null>(null);

  const {
    submit,
    isSubmitting,
    isFinished,
    isError,
  } = PaymentService.useRedeemHardwareUpgradeOffer();
  /*
    INITIAL FETCH
  */

  const { data: offers, isLoading } = RenewalService.useHardwareUpgradeOffers(offerUuid || uuid);

  useEffect(() => {
    if (offers) {
      const firstOffer = offers.find(
        (offer) => offer.type === OfferType.HARDWARE_EARLY_RENEWAL
        || offer.type === OfferType.HARDWARE_UPGRADE_RENEWAL,
      );
      const offer = firstOffer || offers[0];
      setCurrentOffer(offer);
    }
  }, [offers?.length]);

  if (!offers && isLoading) {
    return (
      <div className="pb-50 mb-50 border-b-2 border-grey-350">
        <h5 className="text-24 font-bold text-blue-900">Upgrade Hardware</h5>
        <LoadingIndicator />;
      </div>
    );
  }

  const navigateToShopifyCheckout = (url: string) => {
    if (!url) {
      errorModal.setText('Could not create a checkout for your new Sensors Order. please contact support');
      errorModal.setOpen();
    }
    initRedirect(url);
  };

  const acceptOnClick = (offer: HardwareUpgradeOffer) => {
    submit(offer!.id, offer!.type, (data: CheckoutResponse) => {
      if (data.data.checkout_url) {
        navigateToShopifyCheckout(data.data.checkout_url);
      } else {
        errorModal.setText('Could not reconcile offer');
        errorModal.setOpen();
      }
    });
  };

  const isChargeCard = currentOffer?.type === OfferType.HARDWARE_EARLY_RENEWAL
  || currentOffer?.type === OfferType.HARDWARE_UPGRADE_RENEWAL;
  if (!currentOffer) {
    return null;
  }
  return (
    <div className="pb-50 mb-50 border-b-2 border-grey-350">
      <h5 className="text-24 font-bold text-blue-900">Upgrade Hardware</h5>
      <main className="max-w-[1000px] mx-auto">
        <GenericModal isOpen={(isSubmitting || isFinished) && !isError}>

          {(!isFinished && isChargeCard)
          && (
            <div>
              <LoadingIndicator />
              <p className="text-24 text-blue-900 font-bold my-16">Loading</p>
              <p className="text-16 text-blue-900 mb-12">This might take a minute, do not close this window while processing
              </p>
            </div>
          ) }

          {(isFinished && isChargeCard && !isError)
          && (
            <div>
              <p className="text-24 text-blue-900 font-bold my-16">Loading</p>
              <p className="text-16 text-blue-900 mb-12">This might take a minute, do not close this window while processing</p>
            </div>
          ) }

          {(!isFinished && !isChargeCard) && (
            <div>
              <LoadingIndicator />
              <p className="text-24 text-blue-900 font-bold my-16">Loading </p>
            </div>
          )}

        </GenericModal>
        <NewHardwareOfferTemplate
          offers={offers || []}
          currentOffer={currentOffer}
          changeOffer={(offer) => { setCurrentOffer(offer); }}
          acceptOnClick={acceptOnClick}
        />
      </main>
    </div>
  );
};

export default NewHardwareOffer;
