import CardType from 'payment/domain/CardTypeEnum';
import PaymentMethod from 'payment/domain/PaymentMethod';
import utils from 'payment/utils';
import React from 'react';
import { ReactComponent as CardDetail } from 'svg/card-detail.svg';
import { ReactComponent as PayPal } from 'svg/payment/cards/paypal.svg';
import PaymentError, { toReadable } from 'core/domain/model/PaymentError';
import Tooltip from 'core/Components/Tooltip';

interface CardDetailsProps {
  paymentMethod: PaymentMethod | null,
  error?: PaymentError | null
}

const CardDetails = ({ paymentMethod, error } : CardDetailsProps) => {
  if (paymentMethod === null) return null;

  const isExpiring = paymentMethod.isExpiredByNextChargeDate;
  const isExpired = utils.isAlreadyExpired(paymentMethod);

  const expirationIssue = (isExpired || isExpiring);

  const isPayPal = paymentMethod.type === CardType.PAYPAL;
  const isShopPay = paymentMethod.type === CardType.SHOP_PAY;
  return (
    <div className="flex grow flex-col text-left">
      <div className="flex items-center justify-center md:justify-start grow md:gap-32 flex-wrap">
        {isPayPal ? (
          <div className="flex items-center gap-4 text-grey-700">
            <PayPal
              data-testid="paypal-svg"
              className="inline-block mr-8 h-32 w-32"
            />
            <span className="text-18">
              <b>{paymentMethod.details.email}</b>
            </span>
          </div>
        ) : (
          <>
            <div className="flex items-center gap-4 text-grey-700">
              <CardDetail
                className="inline-block mr-8 h-32 w-32"
              />
              <span className="text-18">
                {isShopPay ? 'Shop Pay'
                  : <b>{utils.formatCardBrand(paymentMethod.details?.brand)}</b>}
              </span>
            </div>
            <div className={`flex justify-center md:justify-start grow text-18 gap-8 md:gap-12 ${expirationIssue ? 'text-red-500' : ''}`}>
              <span>
                <b>****{paymentMethod.details?.last4}</b>
              </span>
              <span>
                <b>{paymentMethod.details?.expMonth}/
                  {paymentMethod.details?.expYear.toString().slice(0)}
                </b>
              </span>
              {isExpired && (
                <span className="text-18 text-red-500">
                  <b>EXPIRED</b>
                </span>
              )}
              {!isExpired && isExpiring ? (
                <span className="text-18 text-red-500">
                  <b>EXPIRING</b>
                </span>
              ) : null }
            </div>
          </>
        )}
      </div>
      {error ? (
        <div className="flex align-baseline text-red-500 pt-12">
          <p className="text-red-500 text-20 font-bold">ERROR</p>
          <div className="stroke-red-500 fill-red-500">
            <Tooltip message={error ? toReadable(error) : ''} type="alert" />
          </div>
        </div>
      ) : null}
    </div>
  );
};

CardDetails.defaultProps = { error: null };

export default CardDetails;
