import React from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import LoadingIndicator from 'core/Components/LoadingIndicator';
import warrantyService from 'warranty/domain/interface/WarrantyService';
import RootPage from 'core/Pages/RootPage';
import { Link, useNavigate } from 'react-router-dom';
import StepProgress from 'warranty/components/StepProgress';
import { WarrantyRequestSummary, WarrantyStatus } from 'warranty/domain/WarrantyRequestSummary';

const Warranties = () => {
  const { data, isLoading } = warrantyService.useWarrantyRequests();
  const navigate = useNavigate();

  if (isLoading) return <LoadingIndicator />;
  if (!data) return <div>No warranties found</div>;

  const cardClass = (warranty: WarrantyRequestSummary) => {
    if (warranty.openCase) return 'border-blue-400 bg-blue-250 shadow-grey-50%-md';
    if (warranty.approved) return 'border-blue-400 bg-white shadow-grey-50%-md';
    return 'bg-white'; // closed
  };
  return (
    <RootPage>
      <div className="flex flex-col gap-32 w-full bg-grey-200 p-40">
        <button
          type="button"
          className="flex items-center text-blue-900"
          onClick={() => navigate('/')}
        >
          <FaChevronLeft className="mr-8" />
          Back
        </button>
        <h5 className="text-24 font-bold text-blue-900">Your Warranties
        </h5>
        {data.map((warranty) => (
          <div
            key={warranty.uuid}
            className={`border rounded-6 py-28 px-24 ${cardClass(warranty)}`}
          >
            {warranty.openCase || warranty.approved ? (
              <StepProgress currentStatus={warranty.warrantyStatus as WarrantyStatus} />
            ) : null}
            <div className="mb-12">

              <div className="flex items-center py-16 border-b border-grey-350">
                <span className="text-18 grow block">Created</span>
                <span className="text-18 flex shrink-0 text-blue-900">
                  {new Date(warranty.createdAt).toLocaleDateString()}
                </span>
              </div>

              <div className="flex items-center py-16 border-b border-grey-350">
                <span className="text-18 grow block">Status</span>
                <span className="text-18 flex shrink-0 te text-blue-900">
                  {warranty.warrantyStatus}
                </span>
              </div>

              {warranty.newOrderNumber && (
                <div className="flex items-center py-16 border-b border-grey-350">
                  <span className="text-18 grow block">Order Number</span>
                  <span className="text-18 flex shrink-0 text-blue-900">
                    {warranty.newOrderNumber}
                  </span>
                </div>
              )}

              {warranty.closureReason && !warranty.approved && (
                <div className="flex items-center py-16 border-b border-grey-350">
                  <span className="text-18 grow block">Closure Reason</span>
                  <span className="text-18 flex shrink-0 text-blue-900">
                    {warranty.closureReason}
                  </span>
                </div>
              )}
            </div>
            {warranty.warrantyStatus === WarrantyStatus.CREATED && (
              <div className="text-center lg:text-right">
                <Link
                  to={`${warranty.uuid}/details`}
                  className="btn bg-transparent border-2 border-blue-400 py-16 px-20 text-blue-400 w-full max-w-300 rounded-6 hover:text-white hover:bg-blue-400 mt-20 lg:mt-0"
                >
                  COMPLETE FORM
                </Link>
              </div>
            )}
          </div>
        ))}
      </div>
    </RootPage>
  );
};

export default Warranties;
