import React from 'react';
import { Link } from 'react-router-dom';
import SkiIqNevado from 'images/Ski_IQ Nevado Cover-optimised.jpg';
import FeedbackLevel from 'images/feedback-level-optimised.jpg';
import TransitionWeightRelease from 'images/transition-weight-release-optimised.jpg';
import AllTerrain from 'images/all-terrain-optimised.jpg';

interface WhatYouLoseTemplateProps {
  currentSubscriptionId : string
}

const WhatYouLoseTemplate = ({ currentSubscriptionId } : WhatYouLoseTemplateProps) => (
  <section className="w-full max-w-710">
    <div className="mb-100">
      <img className="rounded-12 mb-16" src={SkiIqNevado} alt="Ski:IQ 24 Nevado" />
      <div className="lg:mb-100 mb-64">
        <p className="lg:text-30 text-22 text-blue-900 font-bold mb-12">Don&apos;t give up on being a better skier...</p>
        <p className="lg:text-22 text-grey-700 font-medium">If you leave now you&apos;ll lose access to the new features we&apos;ve released this year</p>
      </div>
      <div className="flex flex-row gap-24 items-center mb-80">
        <div className="w-[50%]">
          <h1 className="lg:text-30 text-22 mt-16 text-blue-900 font-bold">Choose the level of feedback you
            want
          </h1>
          <p className="mt-4 mb-22 lg:text-22 font-medium">From no audio to tips on the chairlift to feedback on
            every
            turn.
          </p>
        </div>
        <div className="w-[50%]">
          <img className="w-full h-auto" src={FeedbackLevel} alt="metrics" loading="lazy" />
        </div>
      </div>

      <div className="flex flex-row gap-24 items-center mb-80">
        <div className="w-[50%]">
          <img className="w-full h-auto" src={TransitionWeightRelease} alt="tips and training" loading="lazy" />
        </div>
        <div className="w-[50%]">
          <h1 className="lg:text-30 text-22 mt-16 text-blue-900 font-bold">Get brand new metrics</h1>
          <p className="mt-4 mb-22 lg:text-22 font-medium">Including Transition Weight Release, a powerful tool
            for &apos;all-terrain&apos;.
          </p>
        </div>
      </div>

      <div className="flex flex-row gap-24 items-center">
        <div className="w-[50%]">
          <h1 className="lg:text-30 text-22 mt-16 text-blue-900 font-bold">Take on any terrain</h1>
          <p className="mt-4 mb-22 lg:text-22 font-medium">Carv&apos;s coaching automatically adapts to the terrain you&apos;re skiing.
          </p>
        </div>
        <div className="w-[50%]">
          <img className="rounded-12 mb-16" src={AllTerrain} alt="video coach" loading="lazy" />
        </div>
      </div>
    </div>

    <div>
      <p className="lg:text-14 text-12">
        Please note that any future reactivation is at the discretion of possible price changes.
        <br />
        If you choose to reactivate, you will be able to choose from our
        available plans at the time of reactivation.
      </p>
      <Link
        to="/"
        className="btn btn--pri btn--lg w-full mb-12 mt-24"
        data-id="stay-1-button"
        data-subscription-id={currentSubscriptionId}
      >
        stay on current plan
      </Link>
      <Link
        to={`/renewal/${currentSubscriptionId}/reason`}
        className="btn btn--ter btn--lg w-full my-12"
        data-testid="cancel-button"
        data-id="cancel-continue-1-button"
        data-subscription-id={currentSubscriptionId}
      >
        CANCEL SUBSCRIPTION
      </Link>
    </div>
  </section>
);

export default WhatYouLoseTemplate;
